import ApiInstance from './ApiInstance.js'

export default {
    getInstituteProgramSubjects() {
    return ApiInstance({ requiresAuth: true }).get('/institute/subjects');
  },
  addNewSubjects(Data) {
    return ApiInstance({ requiresAuth: true }).post('/institute/subjects', Data[0]);
  },
  editSubject(Data, id)
  {
    return ApiInstance({ requiresAuth: true }).put(`/institute/subjects/${id}`, Data[0]);
  },
  deleteSubject(id)
  {
    return ApiInstance({ requiresAuth: true }).delete(`/institute/subjects/${id}`);
  }
}
