import ApiInstance from './ApiInstance.js'

export default {
    getInstitutePrograms() {
    return ApiInstance({ requiresAuth: true }).get('/institute/programs');
  },
  addNewPrograms(Data) {
    return ApiInstance({ requiresAuth: true }).post('/institute/programs', Data[0]);
  },
  editProgram(Data, id)
  {
    return ApiInstance({ requiresAuth: true }).put(`/institute/programs/${id}`, Data[0]);
  },
  deleteProgram(id)
  {
    return ApiInstance({ requiresAuth: true }).delete(`/institute/programs/${id}`);
  }
}
