import ApiInstance from "./ApiInstance.js";

export default {
  getInstituteListings() {
    return ApiInstance({ requiresAuth: true }).get("/institute/listings");
  },
  addNewListing(Data) {
    return ApiInstance({ requiresAuth: true }).post(
      "/institute/listings",
      Data
    );
  },
  updateNewListing(Data) {
    return ApiInstance({ requiresAuth: true }).post(
      "institute-listings-update",
      Data
    );
  },
  editCampus(Data, id) {
    return ApiInstance({ requiresAuth: true }).put(`/campuses/${id}`, Data[0]);
  },
  deleteCampus(id) {
    return ApiInstance({ requiresAuth: true }).delete(`/campuses/${id}`);
  },
};
